import { render, staticRenderFns } from "./replenishInfo.vue?vue&type=template&id=18f0f3e7&scoped=true"
import script from "./replenishInfo.vue?vue&type=script&lang=js"
export * from "./replenishInfo.vue?vue&type=script&lang=js"
import style0 from "./replenishInfo.vue?vue&type=style&index=0&id=18f0f3e7&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18f0f3e7",
  null
  
)

export default component.exports