<template>
  <div class="page_box">
    <pageTitle :title="$t('foreign_info')"></pageTitle>
    <el-form :model="ruleForm" ref="ruleForm" label-position="left" :label-width="LOCALE=='en'?'150px':'100px'" class="demoruleForm" v-if="edit">
      <el-form-item :label="$t('dylancompanyName')+'：'"  :class="{el_form_item_width_zh:LOCALE=='zh',el_form_item_width_en:LOCALE=='en'}" >
        <el-input v-model="ruleForm.company_name " size='mini'></el-input>
      </el-form-item>
      <el-form-item :label="$t('companyIndustry')+'：'"    :class="{el_form_item_width_zh:LOCALE=='zh',el_form_item_width_en:LOCALE=='en'}" >
        <el-cascader v-model="page_Industry_Arr"   :show-all-levels="false"  :options="Industry" style="width:264px" size="mini"  ></el-cascader>
      </el-form-item>
      <el-form-item :label="$t('company_net')+'：'" :class="{el_form_item_width_zh:LOCALE=='zh',el_form_item_width_en:LOCALE=='en'}"  >
        <el-input v-model="ruleForm.website"  size='mini'></el-input>
      </el-form-item>
      <el-form-item :label="$t('bussinessCountryCity')+'：'"   :class="{el_form_item_width_zh:LOCALE=='zh',el_form_item_width_en:LOCALE=='en'}"  >
        <div class="county_box">
            <CountrySelect v-model="ruleForm.country" size="mini" class="short country" :placeTitle="$t('countryPlaceholder')" ></CountrySelect>
            <CityComplete v-model="ruleForm.city"  :countryId="ruleForm.country"  size="mini" class="short city" :locale="LOCALE" :placeTitle="this.$t('enterRegion')" ></CityComplete>
        </div>
        
      </el-form-item>
      <el-form-item :label="$t('addressDetaild')+'：'"   :class="{el_form_item_width_zh:LOCALE=='zh',el_form_item_width_en:LOCALE=='en'}"  >
        <el-input v-model="ruleForm.address"  size='mini' type='text-area'></el-input>
      </el-form-item>
    </el-form>
    <div class="base_msg" v-else>
        <div   class="s_box right" :class="{'span_width_en':LOCALE=='en','span_width_zh':LOCALE=='zh'}">
          <div>
            <span class="lable">{{$t('dylancompanyName')}}：</span>
            <p>{{ ruleForm.company_name | textFormat }}</p>
          </div>
          <div>
            <span class="lable">{{ $t('companyIndustry') }}：</span>
            <p>{{ ruleForm.Industry_id | industryFormat(LOCALE) }}</p>
          </div>
          <div>
            <span class="lable">{{$t('address')}}：</span>
            <p style="width:650px"><span>{{!ruleForm.country?'-':"" }}</span>  {{ruleForm.country | countryCityFormat(ruleForm.region_en,ruleForm.region_zh,LOCALE)}} {{ruleForm.address }}</p>
          </div>
          <div>
            <span class="lable">{{$t('company_net')}}：</span>
            <p>{{ ruleForm.website | textFormat }}</p>
          </div>
        </div>
    </div>
    <div class="btn_box">
      <!-- v-if="inspectButton('mem:cai:mdi')" -->
      <el-button type="primary" size='small'   style="min-width:80px;margin-left:32px" :loading="loading" @click="submitForm">{{edit?$t('save'):$t('modify')}}</el-button>
      <el-button size='small'  style="min-width:80px;margin-left:10px" v-if="edit" @click="handleCancel">{{$t('cancel')}}</el-button>
    </div>
  </div>
</template>
<script>
import pageTitle from "~bac/components/common/pageTitle";
import CountrySelect from "@/baseComponents/CountrySelect";
import CityComplete from "@/baseComponents/CityComplete";
import { Industry } from '@/baseUtils/enumValue'
import { countryFormat,industryFormat } from '@/basePlugins/filters'
import { getCityNameById } from '@/baseUtils/AddressConfig/index'
import {priorFormat} from '@/basePlugins/filters'
export default {
  components: {
    pageTitle,
    CountrySelect,
    CityComplete
  },
  data() {
      return {
        Industry:[],
        cascader_props:{
          
        },
        loading:false,
        edit:false,
        show_add:"",
        page_Industry_Arr:[],
        first_load:true,
        page_Company_name:"",
        page_Address:"",
        ruleForm: {
          company_name:'',
          Industry:[],
          Industry_id:null,
          website:"",
          country:'',
          city:'',
          address:"",
          region_en:"",
          region_zh:"",

        },
      };
    },
    watch:{
      "page_Industry_Arr"(cb){
        this.ruleForm.Industry_id=cb[1]
      }
    },
    mounted(){
      this.mypageInit()
    },
    methods: {
      countryFormat,
      getCityNameById,
      priorFormat,
      handleCancel(){
        this.edit=false
        this.mypageInit()
      },
      mypageInit(){
          if (this.LOCALE == "en") {
            Industry.forEach((element) => {
              element.label = element.label_en;
              element.value = element.label_en;
              element.children.forEach((ele) => {
                ele.label = ele.label_en;
              });
            });
          } else {
            Industry.forEach((element) => {
              element.label = element.label_zh;
              element.value = element.label_zh;
              element.children.forEach((ele) => {
                ele.label = ele.label_zh;
              });
            });
          }

          this.Industry=Industry
          this.getMsg()
          // 下面的测试后删除
          // this.get_company()
          // this.get_Bussiness()
      },
      async submitForm(){
        if(!this.edit){
          this.edit=true
          return
        }
        if(this.ruleForm.company_name==""){
          this.$message.warning(this.$t('pleaseinputcompany'))
          return
        }
        if(this.ruleForm.Industry_id==""){
          this.$message.warning(this.$t('Industry_not_null'))
          return
        }
        if(this.ruleForm.country=="" || !this.ruleForm.country){
          this.$message.warning(this.$t('countryPlaceholder'))
          return
        }
        if(this.ruleForm.city=="" || !this.ruleForm.city){
          this.$message.warning(this.$t('cityPlaceholder'))
          return
        }
        if(this.ruleForm.address==""){
          this.$message.warning(this.$t('pleaseinputalladdress'))
          return
        }
        
        this.loading=true
        this.ruleForm['address_'+this.LOCALE]=this.ruleForm.address
        this.ruleForm['name_'+this.LOCALE]=this.ruleForm.company_name
        this.ruleForm['region_'+this.LOCALE]=this.ruleForm.city
        let params={
          user_id:this.USER_INFO.id,
          foreign_info:JSON.stringify(this.ruleForm)
        }
        
        let result = await this.$store.dispatch("baseConsole/company_editAccountInfo",params);
        if(result.success){
          this.loading=false
          this.edit=false
          this.show_add=this.ruleForm.address
          this.$message.success(this.$t('submitSuccess'))
          //更新全据的user_info
          let data = await this.$store.dispatch(
            "baseStore/baseSign_getUserInfo",
            {
              user_id: this.USER_INFO.id,
            });
        }
      },
      async getMsg(){
          try {
            if( this.USER_INFO.foreign_info &&  JSON.parse(this.USER_INFO.foreign_info)){

              let msg=JSON.parse(this.USER_INFO.foreign_info)
              let companyInfo = await this.$store.dispatch("baseConsole/company_getCompanyInfo",{company_id: this.USER_INFO.company_id,is_self: true});
              
              let comData={}
              if(companyInfo && companyInfo.success){
                  comData=companyInfo.data
              }

              this.ruleForm={
                company_name:msg['name_'+this.LOCALE] || comData['name_'+this.LOCALE],
                name_zh:msg.name_zh || comData.name_zh,
                name_en:msg.name_en || comData.name_en,

                Industry_id:msg.Industry_id,
                website:msg.website,
                country:msg.country,
                
                city:msg['region_'+this.LOCALE] || comData['region_'+this.LOCALE],
                region_zh:msg.region_zh || comData.region_zh,
                region_en:msg.region_en || comData.region_en,
                

                address:msg['address_'+this.LOCALE] || comData['address_'+this.LOCALE],
                address_en:msg.address_en || comData.address_en,
                address_zh:msg.address_zh || comData.address_zh,
              }
              let arr=industryFormat(msg.Industry_id,this.LOCALE)
              let newArr = arr.split('-')
              this.page_Industry_Arr.push(newArr[0].trim(),msg.Industry_id)
                
              //----针对小程序进行数据优化
              if(!this.ruleForm.address || this.ruleForm.company_name==""){
                this.get_company()
              }

              if(this.ruleForm.Industry_id==""){
                  this.get_Bussiness()
              }
                 
            }else{
              this.get_company()
              this.get_Bussiness()
            }
          } catch (error) {
          }
          return new Promise(function(resolve, reject){
              resolve();
          })
      },
      async get_company(){
        let companyInfo = await this.$store.dispatch("baseConsole/company_getCompanyInfo",{company_id: this.USER_INFO.company_id,is_self: true});
        if(!companyInfo.success){
          return
        }


        if( this.USER_INFO.foreign_info &&  JSON.parse(this.USER_INFO.foreign_info)){
          
        }else{
          this.ruleForm.company_name=this.priorFormat(companyInfo.data.name_en,companyInfo.data.name_zh,this.LOCALE);
          this.ruleForm.name_zh=companyInfo.data.name_zh
          this.ruleForm.name_en=companyInfo.data.name_en
          this.ruleForm.website=companyInfo.data.website;
        }

          



          this.ruleForm.country=companyInfo.data.country;
          this.ruleForm.city=priorFormat(companyInfo.data.region_en,companyInfo.data.region_zh,this.LOCALE)
          this.ruleForm.region_zh=companyInfo.data.region_zh
          this.ruleForm.region_en=companyInfo.data.region_en
          if(!companyInfo.data.address_en && !companyInfo.data.address_zh ){
              this.ruleForm.address=""
          }else{
            this.ruleForm.address=this.priorFormat(companyInfo.data.address_en,companyInfo.data.address_zh,this.LOCALE)
          }
          this.ruleForm.address_en=companyInfo.data.address_en
          this.ruleForm.address_zh=companyInfo.data.address_zh
        var country=this.countryFormat(companyInfo.data.country,this.LOCALE)
        var city=this.getCityNameById(companyInfo.data.city,this.LOCALE)
        this.show_add=country+"  "+city+"  "+this.ruleForm.address
      },
      async get_Bussiness(){
        if(!this.USER_INFO.company_id){
          return
        }
        let data = await this.$store.dispatch(
          "baseConsole/company_getBussinessInfo",
          {
            company_id: this.USER_INFO.company_id,
          }
        );
        let b_id=data.data.company_industry;
        this.ruleForm.Industry_id=b_id
        let arr=industryFormat(b_id,this.LOCALE)
        let newArr = arr.split('-')
        this.page_Industry_Arr=[]
        this.page_Industry_Arr.push(newArr[0].trim(),b_id.toString())

        this.Industry.forEach((element,index)=>{
            element.children.forEach((ele) => {
              if (ele.value == b_id) {
                this.ruleForm.Industry= [this.Industry[index].label, ele.value];
              }
            });
          })
      }
    }
};
</script>
<style scoped lang='less'>
  .el_form_item_width_zh{
    width: 364px;
  }
  .el_form_item_width_en{
    width: 414px;
  }
  .demoruleForm{
    margin-left: 32px;
  }
  .county_box{
    display: flex;
    justify-content: space-between;
    .country{
      width: 109px;
    }
    .city{
      width: 145px;
    }
  }
  .page_box{
    padding-bottom: 32px;
    .btn_box{
      padding-top: 8px;
    }
  }
  .base_msg{
    padding-top:8px;
    display: flex;
    .span_width_en{
      div span{
        width: 150px;
      }     
    }
    .span_width_zh{
      div span{
        width: 100px;
      }
    }
    .s_box{
      margin-left: 32px;
      display: flex;
      flex-direction: column;
      div{
        display: flex;
        width: 800px;
        padding-bottom:16px;
        .span_width_en{
            width: 150px;
        }
        .span_width_zh{
            width: 100px;
        }
        span{
          // width:100px;
          font-size: 14px;
          color: #606266;
        }
        p{
          width: 650px;
        }
        img{
          width: 100px;
          height: 100px;
        }
      }
    }
  }
</style>