<template>
  <div>
    <pageTitle :title="$t('basicInfo')"></pageTitle>
    <div class="base_msg">
        <div  class="s_box left"  :class="{'span_width_en':LOCALE=='en','span_width_zh':LOCALE=='zh'}" >
          <div>
            <span class="lable">{{$t('headImage')}}：</span>
            <img :src="userInfoForm.avatar?userInfoForm.avatar:DEFAULT_AVATARSQUARE" alt="">
          </div>
          <div>
            <span class="lable">{{$t('name')}}：</span>
            <p>{{ USER_INFO.name_en | priorFormat(USER_INFO.name_zh,LOCALE) }}</p>
          </div>
          <div>
            <span class="lable">{{$t('email')}}：</span>
            <p>{{ USER_INFO.email | textFormat }}</p>
          </div>
          <div>
            <span class="lable">{{$t('phone')}}：</span>
            <p>{{ USER_INFO.mobile | phoneFormat(USER_INFO.area_code) }}</p>
          </div>
          <div>
            <span class="lable">{{$t('gender')}}：</span>
            <p>{{ USER_INFO.sex | sexFormat(LOCALE) }}</p>
          </div>
          <div>
            <span class="lable">{{$t('companyPosition')}}：</span>
            <p>{{ USER_INFO.job_title_en | priorFormat(USER_INFO.job_title_zh,LOCALE) }}</p>
          </div>
          <div>
            <span class="lable">{{$t('birtyday')}}：</span>
            <p>{{ USER_INFO.birthday | secondFormat("LL") }}</p>
          </div>
        </div >
        <div   class="s_box right">
          <div>
            <span class="lable">{{$t('linkedIn')}}：</span>
            <p>{{ USER_INFO.linkedin | textFormat }}</p>
          </div>
          <div>
            <span class="lable">FaceBook：</span>
            <p>{{ USER_INFO.facebook | textFormat }}</p>
          </div>
          <div>
            <span class="lable">Skype：</span>
            <p>{{ USER_INFO.skype | textFormat }}</p>
          </div>
          <div>
            <span class="lable">{{$t('wechat')}}：</span>
            <p>{{ USER_INFO.wechat | textFormat }}</p>
          </div>
          <div>
            <span class="lable">QQ：</span>
            <p>{{ USER_INFO.qq | textFormat }}</p>
          </div>
        </div>
    </div>
    <div class="btn_box">
      <!-- v-if="inspectButton('mem:cai:mdi')" -->
        <el-button type="primary" size='small' style="width:80px;"  class="btn" @click="edit">{{$t('modify')}}</el-button>
    </div>
  </div>
</template>
<script>
import pageTitle from "~bac/components/common/pageTitle";
export default {
  components: {
    pageTitle,
  },
  data(){
    return{
      
      avatarImg:"https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/default-logo.jpg",
      isExamine: false,
      userInfoForm:{}
    }
  },
  mounted() {
    if (JSON.stringify(this.USER_INFO) != {}) {
      // this.avatarImg = this.USER_INFO.avatar;

      this.userInfoForm = {
        name_zh: this.USER_INFO.name_zh,
        name_en: this.USER_INFO.name_en,
        sex: this.USER_INFO.sex,
        job_title_zh: this.USER_INFO.job_title_zh,
        job_title_en: this.USER_INFO.job_title_en,
        birthday: this.USER_INFO.birthday
          ? this.$moment(this.USER_INFO.birthday * 1000).format("YYYY-MM-DD")
          : "",
        linkedin: this.USER_INFO.linkedin,
        facebook: this.USER_INFO.facebook,
        skype: this.USER_INFO.skype,
        wechat: this.USER_INFO.wechat,
        qq: this.USER_INFO.qq,
        avatar: this.USER_INFO.avatar,
      };
    }
  },
  methods:{
    edit(){
      this.$router.push({
          path: "/console/account/userInfo",
          query: {
            parameter: this._encode({
              source: '/console/conference/caseCard/admin',
            }),
          },
        });
    }
  }
};
</script>
<style scoped lang='less'>
  .base_msg{
    display: flex;
    .span_width_en{
      div span{
        width: 150px;
      }     
    }
    .span_width_zh{
      div span{
        width: 100px;
      }
    }
    .s_box{
      // padding-left: 10px;
      margin-left: 32px;
      display: flex;
      flex-direction: column;
      div{
        display: flex;
        width: 500px;
        padding-bottom:14px;
        span{
          width:100px;
          font-size: 14px;
          color: #606266;
        }
        img{
          width: 100px;
          height: 100px;
        }
      }
    }
    .right{
      padding-top:110px ;
      padding-bottom: 10px;
    }
  }
  .btn_box{
    width: 1115px;
    padding-bottom: 32px;
    padding-left: 32px;
    padding-top: 8px;
  }
</style>