<template>
  <div>
    <div class="whiteBg"><info></info></div>
    <div class="line"></div>
    <div class="whiteBg"><companyInfo></companyInfo></div>
    <div class="line"></div>
    <div class="whiteBg"><replenishInfo></replenishInfo></div>
  </div>
</template>
<script>
import info from "~bac/components/conference/caseCard/admin/info";
import companyInfo from "~bac/components/conference/caseCard/admin/companyInfo";
import replenishInfo from "~bac/components/conference/caseCard/admin/replenishInfo";

export default {
  components: {
    info,
    companyInfo,
    replenishInfo,
  },
};
</script>
<style lang="less" scoped>
.line {
  height: 10px;
  background: #f2f2f2;
}
.whiteBg{
  background: #fff;
}
</style>